import React, { useState } from "react";
import { FaCheck, FaPhone, FaUser } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { LINKS, formatCpf } from "../../config";
import { IBase, IBaseCustomer } from "../../interfaces/IBase";
import styles from "../../styles/Bases.module.scss";
import { BsEye } from "react-icons/bs";
import classNames from "classnames";
import { Divider } from "@adobe/react-spectrum";
import { Button } from "react-bootstrap";
import { MdMoneyOff } from "react-icons/md";
import { useSelector } from "react-redux";
import { logOut, selectCurrentToken, selectCurrentUser } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";

interface Props {
    customer: IBaseCustomer;
    isOpenDetails: boolean;
    setOpenDetailId: (id: number | null) => void;
    base: IBase
    hideModal: () => void;
}

export const BaseRow = ({ customer, isOpenDetails, setOpenDetailId, base, hideModal }: Props) => {
    const [localCustomer, setLocalCustomer] = useState(customer); // Local state for customer
    const token = useSelector(selectCurrentToken);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser);

    const [isLoadingCheckbox, setIsLoadingCheckbox] = useState(false);

    const formatSellerName = (seller: string) => {
        if (!seller) return "";
        const [seller_name, seller_lastname] = seller.split(" ");
        return `${seller_name} ${seller_lastname[0]}.`;
    };

    const formatMargin = (margin: string) => {
        const numberMargin = parseFloat(margin);
        if (isNaN(numberMargin)) return margin;

        return numberMargin.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    };

    const handleOpenDetails = () => {
        setOpenDetailId(isOpenDetails ? null : customer.id);
    };

    const handleFinishEvaluation = async () => {
        const response = await axios.put(`${LINKS.API}/bases/evaluation/finish`, {
            customer_id: customer.id,
            base_id: base.id
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": `Bearer ${token}`
            }
        });

        if (response.data.status === 200) {
            toast.success(response.data.message);
            setLocalCustomer({
                ...localCustomer,
                evaluated_at: response.data.data.evaluated_at,
                evaluated_by: response.data.data.evaluated_by
            }); // Update local customer state
        } else if (response.data.status === 401) {
            dispatch(logOut());
            const redirectTo = encodeURIComponent(window.location.pathname);
            navigate(`/login?redirect=${redirectTo}`);
        } else {
            toast.error(response.data.message);
        }
    };

    const handleUpdateDetails = (field: string, value: number) => {
        axios.put(`${LINKS.API}/bases/evaluation/details`, {
            customer_id: customer.id,
            base_id: base.id,
            field: field,
            value: value
        }).then(response => {

            if (response.data.status === 200) {
                setLocalCustomer({
                    ...localCustomer,
                    [field]: value,
                    // evaluated_at: response.data.data.evaluated_at,
                    // evaluated_by: response.data.data.evaluated_by
                }); // Update local customer state
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
            }
        });

    }

    const handleNavigateToProfile = () => {
        navigate(`/clientes/${localCustomer.id}`);
        hideModal();
        setOpenDetailId(null);
    }

    const handleToggleCheck = () => {
        setIsLoadingCheckbox(true);

        axios.put(`${LINKS.API}/bases/customercheck/handle`, {
            customer_id: localCustomer.id,
            base_id: base.id
        }).then(response => {
            if (response.data.status === 200) {
                setLocalCustomer({
                    ...localCustomer,
                    checked: response.data.data.checked
                });
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
                console.log(response.data.message);
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setIsLoadingCheckbox(false);
        });
    }

    return (
        <tr
            className={(localCustomer.checked === 1) ? styles.customer_checked : ""}
        >
            <td>
                {
                    currentUser.type !== "vendedor" ? (
                        localCustomer.id
                    ) :
                        (
                            // If the user is a seller, the customer id becomes a check input (checkbox)
                            <input type="checkbox"
                                checked={localCustomer.checked === 1}
                                onChange={handleToggleCheck}
                                disabled={isLoadingCheckbox}
                            />
                        )
                }
            </td>
            <td>
                {
                    currentUser.type !== "vendedor" ? (
                        <Link to={`/clientes/${localCustomer.id}`} className={styles.customer_name}>
                            {localCustomer.name}
                        </Link>
                    ) : (
                        <span className={styles.customer_name}>
                            {localCustomer.name}
                        </span>
                    )
                }
            </td>
            <td>{formatCpf(localCustomer.cpf)}</td>
            <td className="text-center">{localCustomer.age !== '0' ? localCustomer.age : ""}</td>
            <td>{localCustomer.phone}</td>
            <td className={styles.text_right}>{formatMargin(localCustomer.margin)}</td>
            <td className="text-center">
                {
                    localCustomer.closed_proposal === 1 ? <FaCheck color="green" /> : <IoCloseSharp color="red" />
                }
            </td>
            <td className="text-center">
                {
                    localCustomer.lose_margin === 1 && <FaCheck color="green" />
                }
                {
                    localCustomer.lose_margin === 0 && <IoCloseSharp color="red" />
                }
            </td>
            <td
                className={classNames("text-center", styles.details_button)}

            >
                <div

                    style={{ width: "100%", height: "100%" }}>
                    <BsEye
                        onClick={handleOpenDetails}
                        cursor={"pointer"}
                        color={localCustomer.evaluated_at ? "green" : "black"}
                    />
                </div>
                <div className={classNames(styles.open_details, isOpenDetails && styles.visible)}>
                    <div className="d-flex align-items-center justify-content-around mt-2 w-100">
                        <h6>{localCustomer.name}</h6>
                        <IoCloseSharp
                            cursor={"pointer"}
                            onClick={handleOpenDetails}
                        />
                    </div>
                    <Divider />
                    {
                        localCustomer.seller && <p>Vendedor: {formatSellerName(localCustomer.seller)}</p>
                    }
                    <div className={styles.actions}>
                        <div>
                            <Button
                                title="Vendedor entrou em contato?"
                                variant={localCustomer.contacted === 1 ? "secondary" : "outline-secondary"}
                                disabled={currentUser.type !== "admin" && currentUser.type !== "operacional"}
                                onClick={() => {
                                    if (currentUser.type === "admin" || currentUser.type === "operacional") {
                                        handleUpdateDetails("contacted", localCustomer.contacted ? 0 : 1)
                                    }
                                }}
                            >
                                <FaPhone />
                            </Button>
                        </div>
                        <div>
                            <Button
                                title="Cliente perdeu margem?"
                                variant={localCustomer.lose_margin === 1 ? "danger" : "outline-danger"}
                                disabled={currentUser.type !== "admin" && currentUser.type !== "operacional"}
                                onClick={() => {
                                    if (currentUser.type === "admin" || currentUser.type === "operacional") {
                                        handleUpdateDetails("lose_margin", localCustomer.lose_margin ? 0 : 1)
                                    }
                                }}
                            >
                                <MdMoneyOff />
                            </Button>
                        </div>
                        <div>
                            <Button
                                title="Ver perfil"
                                variant="outline-primary"
                                disabled={currentUser.type !== "admin" && currentUser.type !== "operacional"}
                                onClick={() => {
                                    if (currentUser.type === "admin" || currentUser.type === "operacional") {
                                        handleNavigateToProfile();
                                    }
                                }}
                            >
                                <FaUser />
                            </Button>
                        </div>
                        <div>
                            <Button
                                title="Finalizar avaliação"
                                variant={localCustomer.evaluated_at ? "success" : "outline-success"}
                                disabled={currentUser.type !== "admin" && currentUser.type !== "operacional"}
                                onClick={() => {
                                    if (currentUser.type === "admin" || currentUser.type === "operacional") {
                                        handleFinishEvaluation();
                                    }
                                }}
                            >
                                <FaCheck />
                            </Button>
                        </div>
                    </div>

                    {
                        localCustomer.evaluated_at && (
                            <span className={styles.evaluated}>
                                Avaliado por {formatSellerName(localCustomer.evaluated_by)} em {localCustomer.evaluated_at}
                            </span>
                        )
                    }

                </div>
            </td>
        </tr>
    );
};
