import { Outlet } from "react-router-dom";
import { NavBar } from "./NavBar"
import { SideBar } from "./SideBar/SideBar"
import { Footer } from "./Footer";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../features/auth/authSlice";


export const Layout = () => {
    const token = useSelector(selectCurrentToken);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', background: "white" }}>
            <SideBar />
            <NavBar />
            <Outlet />
            <Footer />
            <Toaster
                position="bottom-right"
                reverseOrder={false}
                toastOptions={{
                    error: {
                        style: {
                            fontSize: '18px',
                            // fontFamily: 'os-light',
                        },
                        iconTheme: {
                            primary: 'white',
                            secondary: 'red',
                        },
                    },
                    success: {
                        style: {
                            fontSize: '18px',
                            // fontFamily: 'os-light',
                        },
                    },
                }}
            />
        </div>

    );
}